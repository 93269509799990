import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import get from "lodash.get";

// Translations
import i18n from "src/translations";

// Utils
import { formatBodyLocationString } from "src/utils/format_location_string";
import { getWoundNumberAndTypeString } from "src/utils/get_wound_number_and_type_string";
import { getSubtypeStringForAnswersJson } from "src/utils/get_wound_subtype_string";

//Constants
import { incompleteBwatScoreValues } from "src/constants";

// Components
import { InHouseAcquiredIconComponent } from "src/components/icon_components";

import { ImageDeclinedContainer } from "src/containers/image_declined_container";

// Styles
import styles from "./style.module.less";

/**
 * Component that renders a card with wound assessment information
 */
export class AssessmentCardComponent extends React.PureComponent {
  static propTypes = {
    assessment: PropTypes.object.isRequired,
    woundNumbersEnabled: PropTypes.bool.isRequired,
    hprScoreEnabled: PropTypes.bool.isRequired,
    bwatScoreEnabled: PropTypes.bool.isRequired,
    assessmentVersion: PropTypes.string.isRequired,
    patientLinksEnabled: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);

    const {
      sendLoadSeriesForSeriesIdAction,
      seriesLoadState,
      seriesId
    } = props;
    sendLoadSeriesForSeriesIdAction(seriesId, seriesLoadState);
  }

  /**
   * Get the wound thumbnail image for this assessment
   */
  renderWoundImage() {
    const { frame } = this.props;
    const url = get(frame, "attributes.thumbnailUrl");
    const contentType = get(frame, "attributes.contentType");
    if (contentType === "declined") {
      return (
        <div className={`${styles["assessment-image"]}`}>
          <ImageDeclinedContainer />
        </div>
      );
    } else if (url) {
      return (
        <div
          className={styles["wound-image"]}
          style={{
            backgroundImage: `url('${url}')`
          }}
        />
      );
    } else {
      return <div className={styles["assessment-image"]} />;
    }
  }

  /**
   * Renders an icon and text to mark this wound as IHA
   */
  renderInHouseAquiredMarker() {
    const { assessment, assessmentVersion } = this.props,
      { attributes } = assessment;
    const inHouseAcquired = get(attributes, "answers.inHouseAcquired", null);
    if (inHouseAcquired == "inHouse") {
      let acquiredString = i18n.t(
        `data.acquired.${assessmentVersion}.${inHouseAcquired}`
      );
      return (
        <div className={styles["in-house-acquired-label"]}>
          <InHouseAcquiredIconComponent />
          <p>{acquiredString}</p>
        </div>
      );
    }
  }

  renderWoundTypeHeading() {
    const {
      assessment,
      seriesLinkEnabled,
      woundNumbersEnabled,
      schemaLocalizationDictionary,
      patientLinksEnabled
    } = this.props;
    const assessmentId = get(assessment, "attributes.assessmentId");

    let woundTypeString = getWoundNumberAndTypeString({
      answersJson: get(assessment, "attributes.answers", {}),
      localizationDictionary: schemaLocalizationDictionary[assessmentId],
      ...(woundNumbersEnabled && {
        patientWoundNumber: get(assessment, "attributes.patientWoundNumber")
      })
    });

    woundTypeString = <h4>{woundTypeString}</h4>;

    return seriesLinkEnabled && patientLinksEnabled ? (
      <Link
        to={`/patients/${assessment.attributes.patientId}/series/${assessment.attributes.seriesId}`}
      >
        {woundTypeString}
      </Link>
    ) : (
      woundTypeString
    );
  }

  renderWoundSubtypeHeading() {
    const {
      assessment,
      subtypeKeysForSchema,
      seriesLinkEnabled,
      schemaLocalizationDictionary,
      patientLinksEnabled
    } = this.props;
    const assessmentId = get(assessment, "attributes.assessmentId");
    const subtype = getSubtypeStringForAnswersJson({
      woundType: get(assessment, "attributes.answers.woundType"),
      answersJson: get(assessment, "attributes.answers", {}),
      subtypeKeysForSchema,
      includePressureUnstageable: true,
      localizationDictionary: schemaLocalizationDictionary[assessmentId]
    });

    if (subtype && seriesLinkEnabled && patientLinksEnabled) {
      return (
        <Link
          to={`/patients/${assessment.attributes.patientId}/series/${assessment.attributes.seriesId}`}
        >
          {subtype}
        </Link>
      );
    } else {
      return <p> {subtype}</p>;
    }
  }

  /**
   * Render the body location for the wound
   */
  renderBodyLocation() {
    const { assessment, schemaLocalizationDictionary } = this.props;
    const location = get(assessment, "attributes.answers.location", "");

    const sublocation = get(assessment, "attributes.answers.sublocation", "");

    const lateralities = get(assessment, "attributes.answers.laterality", []);
    const assessmentId = get(assessment, "attributes.assessmentId");

    const locationString = location
      ? formatBodyLocationString(
          {
            location,
            sublocation,
            lateralities
          },
          schemaLocalizationDictionary[assessmentId]
        )
      : "Not Set";

    return (
      <p>
        <b>Body Location:</b>
        <br />
        {locationString}
      </p>
    );
  }

  /**
   * Render a label for last assessed at
   */
  renderLastAssessedAt() {
    const { assessment } = this.props;

    if (assessment.attributes.revisionCreatedAt) {
      let revisionCreatedAtMoment = moment(
        assessment.attributes.revisionCreatedAt
      );
      let revisionCreatedAtString = revisionCreatedAtMoment.format(
        "MMM Do YYYY"
      );
      let timeSinceLastAssessed = revisionCreatedAtMoment.from();

      return (
        <p className={styles["last-assessed"]}>
          <b>Last Evaluated:</b>
          <br />
          {revisionCreatedAtString}
          <br />({timeSinceLastAssessed})
        </p>
      );
    }
  }

  renderScore(scoreAttributeName) {
    const { assessment } = this.props;
    let score = get(assessment, `attributes.${scoreAttributeName}`, "--");
    const scoreName = scoreAttributeName === "hprScore" ? "HPR" : "BWAT";
    if (incompleteBwatScoreValues.includes(score) && scoreName === "BWAT")
      score = "INC";
    return (
      <p>
        <b>{scoreName} Score: </b>
        <span>{score}</span>
      </p>
    );
  }

  render() {
    return (
      <div className={styles["assessment-card"]}>
        <div className={styles["assessment-card-left"]}>
          {this.renderWoundImage()}
          {this.renderInHouseAquiredMarker()}
        </div>
        <div className={styles["assessment-card-right"]}>
          <div>
            {this.renderWoundTypeHeading()}
            {this.renderWoundSubtypeHeading()}
          </div>
          {this.renderBodyLocation()}
          {this.props.hprScoreEnabled && this.renderScore("hprScore")}
          {this.props.bwatScoreEnabled && this.renderScore("bwatScore")}
          {this.renderLastAssessedAt()}
        </div>
      </div>
    );
  }
}
